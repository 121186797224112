var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-select cursor-pointer", class: _vm.style }, [
      _c(
        "div",
        { ref: "input", staticClass: "input-wrap", on: { click: _vm.open } },
        [
          _c("span", {
            staticClass:
              "form-input rounded-left-0 rounded-right-0 border-right-0 events-disable",
            class: _vm.style,
            domProps: { innerHTML: _vm._s(_vm.name) }
          }),
          _vm._v(" "),
          !_vm.noArrow && !_vm.loading
            ? _c(
                "span",
                {
                  staticClass:
                    "border-btn bg-lighter border-left-0 rounded-left-0 p-0 events-disable",
                  class: _vm.btnStyle
                },
                [
                  _c("i", {
                    class: _vm.active ? "i-chevron-up" : "i-chevron-down"
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { ref: "dropdown", staticClass: "dropdown-wrap" }, [
        _c(
          "div",
          { staticClass: "dropdown", class: { active: _vm.active } },
          [
            _vm.filter
              ? _c("input", {
                  ref: "search",
                  staticClass: "form-input rounded-0 bg-white",
                  attrs: { type: "text", placeholder: "Search.." },
                  on: { keyup: _vm.onKeypress }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("perfect-scrollbar", { staticClass: "scroll-wrap" }, [
              _c(
                "nav",
                { staticClass: "nav flex-column" },
                _vm._l(_vm.options, function(option, i) {
                  return _c("span", {
                    key: i,
                    staticClass: "btn",
                    class: { selected: _vm.selected === i },
                    domProps: { innerHTML: _vm._s(option.name) },
                    on: {
                      click: function($event) {
                        return _vm.select(option)
                      },
                      mouseenter: function($event) {
                        _vm.selected = i
                      }
                    }
                  })
                }),
                0
              )
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }