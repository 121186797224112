<template>
  <div class="form-group">

    <label v-if="label" class="form-label">{{label}}</label>

    <div class="form-select cursor-pointer"
         :class="style">

      <div class="input-wrap"
           ref="input"
           @click="open">

        <span class="form-input rounded-left-0 rounded-right-0 border-right-0 events-disable" :class="style" v-html="name"></span>

        <span class="border-btn bg-lighter border-left-0 rounded-left-0 p-0 events-disable"
              :class="btnStyle"
              v-if="!noArrow && !loading">
          <i :class="active ? 'i-chevron-up' : 'i-chevron-down'"></i>
        </span>
      </div>

        <div class="dropdown-wrap" ref="dropdown">
          <div class="dropdown" :class="{active}">

            <input class="form-input rounded-0 bg-white" ref="search" type="text" placeholder="Search.."
                   v-if="filter"
                   @keyup="onKeypress">

            <perfect-scrollbar class="scroll-wrap">

              <nav class="nav flex-column">
                <span class="btn"
                      :class="{'selected': selected === i}"
                      @click="select(option)"
                      @mouseenter="selected = i"
                      v-for="(option, i) in options"
                      :key="i" v-html="option.name"></span>
              </nav>
            </perfect-scrollbar>
          </div>
        </div>
    </div>

    <span v-if="help !== undefined" class="form-help">{{help}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: [String, Number],
      label: [String, Number],
      loading: Boolean,
      filter: Boolean,
      noPadding: Boolean,
      noArrow: Boolean,
      help: {
        type: [String, Number],
        default: undefined
      },
      options: {
        type: Array,
        required: true
      },
      icon: String,
      value: [String, Number],
      valid: {
        type: Boolean,
        default: true
      },
      warning: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _value: {
        get () { return this.value },
        set (value) {
          this.$emit('input', value);
        }
      },
      style() {
        return {
          'px-0': this.noPadding,
          'events-disable': this.loading,
          'form-success': this.success,
          'form-warning': this.warning,
          'form-danger': !this.valid,
          'form-primary': this.valid
        }
      },
      name() {
        return this.state.name || this.state.value || ''
      },
      btnStyle() {
        return {
          'border-btn-success': this.success,
          'border-btn-warning': this.warning,
          'border-btn-danger': !this.valid,
          'border-btn-primary': this.valid
        }
      }
    },
    data() {
      return {
        active: false,
        selected: -1,
        search: '',
        state: {}
      }
    },
    watch: {
      _value() {
        this.checkState()
      },
      options() {
        this.checkState()
      }
    },
    mounted () {

      this.checkState();

      document.addEventListener("click", (e) => {

        let p = e.target;

        while(p)
        {
          if(p && p === this.$refs['dropdown'] || p && p === this.$refs['input'])
            return;

          p = p.parentElement;
        }

        this.close()
      });

      document.addEventListener("keydown", (e) => {

        if(this.active)
          e.preventDefault();
      });

      document.addEventListener("keyup", (e) => {

        if(this.active)
        {
          e.preventDefault();
          e = e || window.event;

          if(e.keyCode === 13)
            this.select(this.options[this.selected])

          if(e.keyCode === 38 && this.selected > 0)
            this.selected--

          if(e.keyCode === 40 && this.selected < this.options.length - 1)
            this.selected++
        }
      });
    },
    methods: {

      open() {
        this.search = '';
        this.$emit('target');

        if(this.options.length > 0)
          this.active = !this.active;

        setTimeout(() => {
          if(this.$refs['search'])
            this.$refs['search'].focus();
        }, 100)
      },

      onKeypress(e) {
        e.preventDefault();
        this.$emit('search', e.target.value)
      },

      close() {
        this.active = false;
        this.selected = -1;

        if(this.$refs['search'])
          this.$refs['search'].value = '';

        this.$emit('search', '')
      },

      checkState() {
        this.options.map(option => {
          if(option.value == this._value)
            this.state = option;
        });
      },

      select(data) {
        this.state = data;
        this._value = data.value;

        this.close();
        this.$emit('select', data.value);
      }
    }
  }
</script>
